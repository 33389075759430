import React from "react"
import { Container, Row, Col, Image} from "react-bootstrap"
import NoobsLogo from "../../../../images/NOOBS_SHORT_LOGO.png"
import NoobPunks from "../../../../images/NOOBPUNKS_SHORT_LOGO.png"
import NoobZoo from "../../../../images/NOOBZOO_SHORT_LOGO.png"

const styles = require("./landing-page.module.scss")

type LandingPageBodyProps = {}

/**
 * Home page for Noobs.
 */
const LandingPageBody: React.FC<LandingPageBodyProps> = ({}) => {
  return (
    <>
      <Container id="home-intro" className="d-flex flex-column align-items-center justify-content-center">
        <Row>
          <Col>
            <h1 className={styles.textHeader}>ENTER THE NOOBVERSE</h1>
          </Col>
        </Row>
        <Row style={{ width: "100%" }}>
          <Col className="d-flex justify-content-center col-12 col-md-4">
            <a href="/noobs">
              <Image src={NoobsLogo} className="noobs-project-image" />
            </a>
          </Col>
          <Col className="d-flex justify-content-center col-12 col-md-4">
            <a href="/punks">
              <Image src={NoobPunks} className={`${styles.punksLogo} noobs-project-image`} />
            </a>
          </Col>
          <Col className="d-flex justify-content-center col-12 col-md-4">
            <a href="/zoo" style={{ textDecoration: "none" }}>
              <Image src={NoobZoo} className={`${styles.zooLogo} noobs-project-image`} />
            </a>
          </Col>
        </Row>
      </Container>
    </>
  )
}

export default LandingPageBody
